import "./App.css";
import React, {useEffect, useState} from "react";
import EULayout from "../src/regionLayouts/EULayout";
import USLayout from "../src/regionLayouts/USLayout";
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter as Router} from "react-router-dom";

function App() {
    const [region, setRegion] = useState(null);

    useEffect(() => {
        fetch("https://icanhazip.com/")
            .then((response) => response.text())
            .then((ip) => {
                fetch(`https://api.appliedmed.com/GeoLocation/api/GetUserIPAddressHCPInformation?
                ip=${ip.trim()}&ApiKey=${process.env.REACT_APP_SECRET_NAME}`)
                    .then((response) => response.json())
                    .then((data) => {
                        const {countryName} = data.Results;
                        const region = determineRegion(countryName);
                        setRegion(region);
                    })
                    .catch(() => {
                        setRegion("Non-EU"); // Fallback region
                    });
            })
            .catch(() => {
                setRegion("Non-EU");
            });
    }, []);

    const determineRegion = (countryName) => {
        const euCountries = [
            "Germany",
            "France",
            "Spain",
            "Italy",
            "Netherlands",
            "Belgium",
            "Sweden",
            "Finland",
            "Poland",
            "Austria",
            "Switzerland",
            "Ireland",
            "Portugal",
            "Norway",
            "Denmark",
            "United Kingdom"];

        if (euCountries.includes(countryName)) {
            console.log("EU");
            return "EU";
        } else {
            console.log("US");
            return "US";
        }
    };

    if (region === null) {
        return <div></div>;  // Display while fetching region
    }
    return (
        <HelmetProvider>
            <Router>
                {region === "EU" ? <EULayout region={region}/> : <USLayout region={region}/>}
            </Router>
        </HelmetProvider>
    )
}

export default App;