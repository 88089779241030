import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./HealthcareProviders.css";
import { EUBanner } from "../../../Banner/Banner";
import { Button } from "../../../Button/Button";
import { TestimonalQuote } from "../../../TestimonalQuote/TestimonalQuote";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet-async";
import { cdn } from "../../../../consts/cdn";
import { JwPlayer } from "../../../JwPlayer/JwPlayer.js";

//Images
const BannerDesktop = cdn.images + "HealthcareProviders/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "HealthcareProviders/Banner-Mobile.jpg";
const Thumbnail1 = cdn.images + "HealthcareProviders/Thumbnail1-1.jpg";
const Thumbnail2 = cdn.images + "HealthcareProviders/Thumbnail2.jpg";
const Image1 = cdn.images + "HealthcareProviders/Image1.jpg";
const Circle1 = cdn.images + "HealthcareProviders/Group 1591.svg";
const Circle2 = cdn.images + "HealthcareProviders/Group 1400.svg";
const youtube = cdn.images + "HealthcareProviders/NoPath - Copy.svg";
const twitter = cdn.images + "HealthcareProviders/NoPath.svg";
const email = cdn.images + "HealthcareProviders/Path 1563.svg";

function HealthcareProviders() {
  return (
    <div className="hcphome">
      <Helmet>
        <title>
          vNOTES – Advanced Minimally Invasive Gynecologic Procedures
        </title>
        <link
          rel="canonical"
          href="https://www.vnotes.com/healthcareprofessionals"
        />
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) workshops provide a comprehensive understanding of procedural steps and instrumentation used…"
        />
      </Helmet>
      <EUBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="vNOTES"
        text1="The Next Evolution in Minimally"
        text2="Invasive Gynecologic Surgery"
        color="#365B7E"
        openSans="true"
      />
      <div className="content">
        <div className="hcphome__extrapadding">
          <h1 className="header-primary header-primary--blue header-primary--comfortaa">
            WHY{" "}
            <span
              style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}
            >
              v
            </span>
            NOTES?
          </h1>
          <p className="paragraph">
            Vaginal hysterectomy is the recommended approach by ACOG (American
            College of Obstetricians and Gynecologists) and AAGL (American
            Association of Gynecologic Laparoscopists) whenever indicated and
            feasible.
            <sup
              className="ref__style"
              data-background-color="#FAFAFA"
              data-text-color="#707070"
              data-tip="Committee Opinion No 701: Choosing the Route of Hysterectomy for Benign Disease. <i>Obstet Gynecol</i>. 2017;129(6):e155-e159. doi:10.1097/AOG.0000000000002112<br/><br/>AAGL Advancing Minimally Invasive Gynecology Worldwide. AAGL position statement: route of hysterectomy to treat benign uterine disease. <i>J Minim Invasive Gynecol</i>. 2011;18(1):1-3. doi:10.1016/j.jmig.2010.10.001"
              data-class="sup__style"
              data-arrow-color="transparent"
              data-html="true"
            >
              REF
            </sup>{" "}
            Despite these recommendations and supporting clinical evidence, the
            rate of vaginal hysterectomies has steadily declined. Some of the
            challenges that contribute to lower adoption of the vaginal approach
            include lack of visibility and reduced access to the anatomy. Access
            to the adnexa can be particularly challenging, with failure rates of
            up to 36%.
            <sup
              className="ref__style"
              data-background-color="#FAFAFA"
              data-text-color="#707070"
              data-tip="Robert M, Cenaiko D, Sepandj J, Iwanicki S. Success and Complications of Salpingectomy at the Time of Vaginal Hysterectomy. <i>J Minim Invasive Gynecol</i>. 2015;22(5):864-869. doi:10.1016/j.jmig.2015.04.012<br/><br/>Mothes AR, Schlachetzki A, Nicolaus K, et al. LAVH superior to TVH when concomitant salpingo-oophorectomy is intended in prolapse hysterectomy: a comparative cohort study. <i>Arch Gynecol Obstet</i>. 2018;298(6):1131-1137. doi:10.1007/s00404-018-4909-z"
              data-class="sup__style"
              data-arrow-color="transparent"
              data-html="true"
            >
              REF
            </sup>{" "}
            <br />
            <br />
            vNOTES (vaginal natural orifice transluminal endoscopic surgery)
            modernizes vaginal surgery. New advanced access platforms enable
            access into the peritoneal cavity through the vagina, effectively
            combining the benefits of laparoscopic and traditional vaginal
            surgery.
          </p>
        </div>

        <div className="hcphome__testimonal-container">
          <div className="hcphome__testimonal-video">
            <JwPlayer
              file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/7894b872-d89f-42c4-8c2f-7763c81e4a93/380835-EN-B_vNOTES Hysterectomy .ism/manifest(format=m3u8-cmaf)"
              title="vNOTES Hysterectomy Featuring the GelPOINT V‑Path Transvaginal Access Platform"
              image={Thumbnail1}
            ></JwPlayer>
            <div className="video-description">
              <p>
                vNOTES Hysterectomy Featuring the GelPOINT V‑Path Transvaginal
                Access Platform
              </p>
            </div>
          </div>

          <div className="hcphome__testimonal-video">
            <JwPlayer
              file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/66f2e943-684c-48c5-8deb-f20d4c8a9e79/Highlight_ vNOTES as Featured on.ism/manifest(format=m3u8-cmaf)"
              title="The Benefits of vNOTES as Featured on ACOG TV"
              image={Thumbnail2}
            ></JwPlayer>
            <div className="video-description">
              <p>The Benefits of vNOTES as Featured on ACOG TV</p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ background: "#fafafa", zIndex: "2" }}>
        <img
          src={Image1}
          className="hcphome__img--1"
          alt="img1"
          loading="lazy"
        />
      </div>

      <div className="hcphome__gray-container hcphome__gray-container--1">
        <img className="hcphome__circle-svg--1" src={Circle1} alt="circle" />
        <div className="content" style={{ marginTop: "0", paddingTop: "0" }}>
          <h1
            className="header-primary header-primary--blue header-primary--comfortaa"
            style={{ marginTop: "0", paddingTop: "0" }}
          >
            What are the <br className="br__desktop-only" />
            benefits of{" "}
            <span
              style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}
            >
              v
            </span>
            NOTES?
          </h1>
          <div className="hcphome__button-container">
            <div className="modal__btn-left-container">
              <Button className="modal__btn--left" route="/hcptestimonials">
                View HCP Testimonials
              </Button>
            </div>
            <div className="modal__btn-right-container">
              <Button
                className="modal__btn--right"
                route="/patientstestimonials"
              >
                View Patient Testimonials
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="hcphome__section-container">
        <div className="hcphome__section hcphome__section--one">
          <h3 className="hcphome__section-header">
            vNOTES enhances visualization and access compared to traditional
            vaginal surgery
            <sup
              className="ref__style"
              data-background-color="#FAFAFA"
              data-text-color="#707070"
              data-tip="Lee CL, Wu KY, Su H, Wu PJ, Han CM, Yen CF. Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery (NOTES): a series of 137 patients. <i>J Minim Invasive Gynecol</i>. 2014;21(5):818-824. doi:10.1016/j.jmig.2014.03.011"
              data-class="sup__style"
              data-arrow-color="transparent"
              data-html="true"
            >
              REF
            </sup>
            :
          </h3>
          <ul className="hcp-li-bullets">
            <li className="paragraph">
              Easier identification of ureters
              <sup
                className="ref__style"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Lowenstein L, Baekelandt J, Paz Y, Lauterbach R, Matanes E. Transvaginal Natural Orifice Transluminal Endoscopic Hysterectomy and Apical Suspension of the Vaginal Cuff to the Uterosacral Ligament. <i>J Minim Invasive Gynecol</i>. 2019;26(6):1015. doi:10.1016/j.jmig.2019.04.007"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </li>
            <li className="paragraph">More complete abdominal exploration</li>
            <li className="paragraph">
              Greater ability to address pelvic floor support
              <sup
                className="ref__style"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Lowenstein L, Baekelandt J, Paz Y, Lauterbach R, Matanes E. Transvaginal Natural Orifice Transluminal Endoscopic Hysterectomy and Apical Suspension of the Vaginal Cuff to the Uterosacral Ligament. <i>J Minim Invasive Gynecol</i>. 2019;26(6):1015. doi:10.1016/j.jmig.2019.04.007"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </li>
            <li className="paragraph">
              Increased ability to remove adnexa and large uteri
              <sup
                className="ref__style"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Baekelandt J, Cavens D. GelPOINT (Applied Medical) is a suitable port for transvaginal NOTES procedures. <i>J Gynecol Surg</i>. 2016;32(5):257-262. doi:10.1089/gyn.2016.0013"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </li>
            <li className="paragraph">Better ability to teach</li>
          </ul>
        </div>
        <div className="hcphome__section hcphome__section--two">
          <h3 className="hcphome__section-header">
            vNOTES has been shown to provide the following benefits compared to
            laparoscopic surgery:
          </h3>
          <ul>
            <li className="paragraph">
              Less postoperative pain and analgesics
              <sup
                className="ref__style"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Baekelandt JF, De Mulder PA, Le Roy I, et al. Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery versus laparoscopy as a day-care procedure: a randomised controlled trial. <i>BJOG</i>. 2019;126(1):105-113. doi:10.1111/1471-0528.15504"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </li>
            <li className="paragraph">
              Improved ergonomics for surgeons
              <sup
                className="ref__style"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Burnett AF, Rojo MO. Vaginal natural orifice transluminal endoscopic surgery for gynecologic and gynecologic oncology procedures. <i>IntechOpen</i>. 2020;1(9) doi:10.5772/intechopen.96082"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </li>
            <li className="paragraph">
              Better cosmesis
              <sup
                className="ref__style"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Lowenstein L, Baekelandt J, Paz Y, Lauterbach R, Matanes E. Transvaginal Natural Orifice Transluminal Endoscopic Hysterectomy and Apical Suspension of the Vaginal Cuff to the Uterosacral Ligament. <i>J Minim Invasive Gynecol</i>. 2019;26(6):1015. doi:10.1016/j.jmig.2019.04.007"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </li>
            <li className="paragraph">
              Faster recovery
              <sup
                className="ref__style"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Baekelandt JF, De Mulder PA, Le Roy I, et al. Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery versus laparoscopy as a day-care procedure: a randomised controlled trial. <i>BJOG</i>. 2019;126(1):105-113. doi:10.1111/1471-0528.15504"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </li>
            <li className="paragraph">
              Fewer postoperative infections
              <sup
                className="ref__style"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Housmans S, Noori N, Kapurubandara S, et al. Systematic Review and Meta-Analysis on Hysterectomy by Vaginal Natural Orifice Transluminal Endoscopic Surgery (vNOTES) Compared to Laparoscopic Hysterectomy for Benign Indications. <i>J Clin Med</i>. 2020;9(12):3959. Published 2020 Dec 7. doi:10.3390/jcm9123959"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </li>
          </ul>
        </div>
      </div>

      <div className="hcphome__gray-container hcphome__gray-container--4">
        <div className="hcphome__extrapadding">
          <TestimonalQuote
            quote="vNOTES solves the challenges of vaginal surgery by allowing additional visualization. It enables you to see the patient’s anatomy, as well as any scar tissue or adhesions. Also, as surgeons, we find it helps us teach our residents how to perform surgery correctly and safely."
            name="Erica Stockwell,&nbsp;"
            credential="DO, MBA, FACOG "
          />
        </div>
      </div>

      <div className="content">
        <h1 className="header-primary header-primary--blue header-primary--comfortaa">
          HOW CAN I ADVANCE MY
          <br className="br__desktop-only" /> PRACTICE WITH{" "}
          <span style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}>
            v
          </span>
          NOTES?
        </h1>
        <p className="paragraph">
          vNOTES courses provide a comprehensive understanding of vNOTES
          procedural steps and instrumentation used in the treatment of benign
          gynecologic pathologies. The course includes the following:
        </p>
        <ul className="hcp__paragraph-li">
          <li className="paragraph">
            Didactic lectures reviewing indications, clinical literature,
            anatomy, and instrument placement
          </li>
          <li className="paragraph">
            Hands-on practicum with lifelike Simsei<sup>®</sup> tissue models to
            simulate vNOTES
          </li>
          <li className="paragraph">
            Expert guidance and feedback from the faculty
          </li>
          <li className="paragraph">
            Continued education and support after course completion
          </li>
        </ul>
      </div>

      <div className="hcphome__gray-container hcphome__gray-container--2">
        <img className="hcphome__circle-svg--2" src={Circle2} alt="circle" />
        <div
          className="content hcphome__right-content"
          style={{ marginTop: 0, paddingTop: 0, width: "100%" }}
        >
          <h1
            className="header-primary header-primary--blue header-primary--comfortaa"
            style={{ margin: "0px" }}
          >
            <span
              style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}
            >
              v
            </span>
            NOTES COURSES
          </h1>
          <p className="hcphome__subheader">
            Contact us for more information about vNOTES{" "}
            <br className="br__desktop-only" /> and course availability in your
            region.{" "}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button route="/contactus">Contact Us</Button>
          </div>
        </div>
      </div>

      <div className="hcphome__gray-container hcphome__gray-container--3">
        <div className="center">
          <div>
            <h1 className="header-primary header-primary--blue header-primary--comfortaa max-content">
              CONNECT WITH US
            </h1>
            <div className="hcphome__button-container hcphome__button-container--3">
              <a
                href="https://twitter.com/AppliedMed_GYN"
                className="eventstile__link"
                target="_blank"
                rel="noreferrer"
              >
                <button className="hcphome__button btn">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="connectus__icon connectus__icon--twitter"
                      src={twitter}
                      alt=""
                      loading="lazy"
                    />
                    <span>Twitter</span>
                  </div>
                </button>
              </a>
              <a
                href="https://www.youtube.com/watch?v=2ECKUHwndoA&list=PLpuXcg1HvAD1x_Zz_GJ0rMDyOis4i1t7K"
                className="eventstile__link"
                target="_blank"
                rel="noreferrer"
              >
                <button className="hcphome__button btn">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="connectus__icon connectus__icon--twitter"
                      src={youtube}
                      alt=""
                      loading="lazy"
                    />
                    <span>YouTube</span>
                  </div>
                </button>
              </a>
              <a
                href="mailto:vNOTES@appliedmedical.com"
                className="eventstile__link"
                target="_blank"
                rel="noreferrer"
              >
                <button className="hcphome__button btn">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="connectus__icon connectus__icon--twitter"
                      src={email}
                      alt=""
                      loading="lazy"
                    />
                    <span>Email</span>
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
}

export default HealthcareProviders;
