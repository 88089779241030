import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./NotFound.css";
import { USBanner } from "../../../Banner/Banner";

import { cdn } from "../../../../consts/cdn";

//Images
const BannerDesktop = cdn.images + "NotFound/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "NotFound/Banner-Mobile.jpg";

function NotFound() {
  return (
    <div className="notFound">
      <USBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="404"
        text1="We can't seem to find the page"
        text2="that you're looking for."
        button1="Go Back Home"
        route1="/"
        color="#365B7E"
        openSans="true"
      />
    </div>
  );
}

export default NotFound;
