import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

export const Button = ({
  children,
  type,
  route = "#",
  width,
  id,
  styles,
  onClick,
  disabled,
}) => {
  // const styles = {
  //   width: width,
  // };

  return (
    <Link to={route} className="btn-link" id={id}>
      <button
        style={styles}
        className="btn"
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </Link>
  );
};
