import React from "react";
import "../../../../../App.css";
import "../../../../_Shared/utility.css";
import "./Patient-testimonials.css";
import { EUBanner } from "../../../../Banner/Banner";
import { Helmet } from "react-helmet-async";
import TestimonalQuote from "../../../../TestimonalQuote/TestimonalQuote";
import { cdn } from "../../../../../consts/cdn";
import { JwPlayer } from "../../../../JwPlayer/JwPlayer.js";

//images
const VideoThumbnail1 = cdn.images + "Testimonials/Patients/Thumbnail1.jpg";
const VideoThumbnail2 = cdn.images + "Testimonials/Patients/Thumbnail2.jpg";
const VideoThumbnail3 = cdn.images + "Testimonials/Patients/Thumbnail3.jpg";
const BannerDesktop = cdn.images + "Testimonials/Patients/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "Testimonials/Patients/Banner-Mobile.jpg";

function PatientTestimonial() {
  return (
    <div className="testimonial">
      <Helmet>
        <title>vNOTES Hysterectomy</title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) is another minimally invasive approach to consider for your hysterectomy and other gynecologic…"
        />
      </Helmet>
      <EUBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="TESTIMONIALS"
        text1="Patients"
        color="#365B7E"
        openSans="true"
      />
      <div className="content">
        <div className="patient__video-container patient__video-container--1">
          <div className="testimonial__content--1">
            <JwPlayer
              file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/6ee3874f-812e-495d-b0b2-e846d741e3e1/376096-EN-USA-A_vNOTES Hysterect.ism/manifest(format=m3u8-cmaf)"
              title="vNOTES Hysterectomy: A Patient's Perspective on Recovery"
              image={VideoThumbnail1}
            ></JwPlayer>
            <div className="video-description">
              <p>vNOTES Hysterectomy: A Patient's Perspective on Recovery</p>
            </div>
          </div>
        </div>

        <div className="patient__video-container patient__video-container--2">
          <div className="testimonial__content--2">
            <JwPlayer
              file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/d4ce6021-ff85-4b2e-910e-e32cee77ce5a/365724-EN-USA-A_Ready to Live Li.ism/manifest(format=m3u8-cmaf)"
              title="Ready to Live Life Again with vNOTES"
              image={VideoThumbnail2}
            ></JwPlayer>
            <div className="video-description">
              <p>Ready to Live Life Again with vNOTES</p>
            </div>
          </div>

          <div className="testimonial__content--2">
            <JwPlayer
              file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/9237ccd1-9914-47c8-a63f-93e574eb4233/375009-EN-USA-A_vNOTES Hysterect.ism/manifest(format=m3u8-cmaf)"
              title="vNOTES Hysterectomy: A Patient's Perspective on Pain"
              image={VideoThumbnail3}
            ></JwPlayer>
            <div className="video-description">
              <p>vNOTES Hysterectomy: A Patient's Perspective on Pain</p>
            </div>
          </div>
        </div>

        <div className="hcp__div-review">
          <TestimonalQuote
            quote="I was really surprised when I was in recovery, because I could get
          up on my own and I could move and go to the restroom. The little
          things you don't expect you would be able to do for a while, I was
          able to do very, very quickly, I mean right after surgery."
            name="Brittany Villeneuve"
          />
        </div>

        <div className="hcp__div-review">
          <TestimonalQuote
            quote="The vNOTES procedure and my surgeon made it possible for me to just have a little blip on life and just keep on going, and I feel so much better after the procedure!"
            name="Melissa Arrowood"
          />
        </div>
      </div>
    </div>
  );
}

export default PatientTestimonial;
