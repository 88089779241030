import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import "./Navbar.css";
import { ModalContext } from "../../../regionLayouts/EULayout";
import { checkHCPStatus } from "../../../utilities/CheckHCPStatus";
import { cdn } from "../../../consts/cdn";
import { ChevronDown } from "react-bootstrap-icons";
const Logo = cdn.images + "Logo/vNOTES Logo.svg";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  let [activeLink, setActiveLink] = useState("");
  let location = useLocation();
  const [, , , setIsHCPModalVisible, , setForwardPath] =
    useContext(ModalContext);
  const history = useHistory();

  useEffect(() => {
    const displayActiveNav = () => {
      if (location.pathname === "/eu/") {
        setActiveLink("1");
      } else if (
        location.pathname === "/eu/healthcareprofessionals" ||
        location.pathname === "/eu/clinicalevidence" ||
        location.pathname === "/eu/events"
      ) {
        setActiveLink("2");
      } else if (
        location.pathname === "/eu/patients" ||
        location.pathname === "/eu/findasurgeon"
      ) {
        setActiveLink("3");
      } else if (
        location.pathname === "/eu/hcptestimonials" ||
        location.pathname === "/eu/patientstestimonials"
      ) {
        setActiveLink("4");
      }
    };

    displayActiveNav();
  });

  return (
    <nav className="navbar">
      <div className="navbar__side--left">
        <NavLink to="/eu/">
          <img
            className="navbar__logo"
            src={Logo}
            alt="logo"
            onClick={() => {
              setActiveLink("1");
              setClick(false);
            }}
          />
        </NavLink>

        <input
          type="checkbox"
          className={
            click ? "navigation__checkbox active" : "navigation__checkbox"
          }
          id="navi-toggle"
          onClick={handleClick}
        />
        <label
          htmlFor="navi-toggle"
          className="navigation__button navbar__menu-icon"
        >
          <span className="navigation__icon">&nbsp;</span>
        </label>
      </div>
      <ul
        className={
          click
            ? "navbar__side--right navbar__nav-menu active"
            : "navbar__side--right navbar__nav-menu"
        }
      >
        <li className="navbar__nav-item">
          <NavLink
            exact
            to="/eu/"
            className={
              activeLink === "1" ? "navbar__label active" : "navbar__label"
            }
            onClick={() => {
              setActiveLink("1");
              setClick(false);
            }}
            activeStyle={{
              color: "#365B7E",
              borderBottom: "2px solid #365B7E",
            }}
          >
            Home
          </NavLink>
        </li>
        <hr className="horizontal-line" />
        <li className="navbar__nav-item navbar__nav-item--2">
          <input
            type="checkbox"
            id="healthcare-professionals"
            name="healthcare-professionals"
            value="Healthcare Professionals"
            className="navbar__checkbox"
          />
          <label
            className={
              activeLink === "2" ? "navbar__label active" : "navbar__label"
            }
            htmlFor="healthcare-professionals"
          >
            Healthcare Professionals
            <ChevronDown className="expansion-arrow" />
          </label>

          <div className="navbar__dropdown">
            <NavLink
              exact
              to="/eu/healthcareprofessionals"
              className="navbar__nav-links"
              onClick={(e) => {
                checkHCPStatus(
                  e,
                  setIsHCPModalVisible,
                  history,
                  "/eu/healthcareprofessionals",
                  setForwardPath
                );
                setActiveLink("2");
                setClick(false);
              }}
              activeStyle={{
                color: "#365B7E",
                borderBottom: "2px solid #365B7E",
              }}
            >
              vNOTES
            </NavLink>

            <NavLink
              exact
              to="/eu/clinicalevidence"
              className="navbar__nav-links"
              onClick={(e) => {
                checkHCPStatus(
                  e,
                  setIsHCPModalVisible,
                  history,
                  "/eu/clinicalevidence",
                  setForwardPath
                );
                setActiveLink("2");
                setClick(false);
              }}
              activeStyle={{
                color: "#365B7E",
                borderBottom: "2px solid #365B7E",
              }}
            >
              Clinical Evidence
            </NavLink>

            <NavLink
              exact
              to="/eu/events"
              className="navbar__nav-links"
              onClick={(e) => {
                checkHCPStatus(
                  e,
                  setIsHCPModalVisible,
                  history,
                  "/eu/events",
                  setForwardPath
                );
                setActiveLink("2");
                setClick(false);
              }}
              activeStyle={{
                color: "#365B7E",
                borderBottom: "2px solid #365B7E",
              }}
            >
              Events
            </NavLink>
          </div>
        </li>
        <hr className="horizontal-line" />
        <li className="navbar__nav-item navbar__nav-item--3">
          <input
            type="checkbox"
            id="patients"
            name="patients"
            value="patients"
            className="navbar__checkbox"
          />
          <label
            className={
              activeLink === "3" ? "navbar__label active" : "navbar__label"
            }
            htmlFor="patients"
          >
            Patients
            <ChevronDown className="expansion-arrow" />
          </label>

          <div className="navbar__dropdown">
            <NavLink
              exact
              to="/eu/patients"
              className="navbar__nav-links"
              onClick={() => {
                setActiveLink("3");
                setClick(false);
              }}
              activeStyle={{
                color: "#365B7E",
                borderBottom: "2px solid #365B7E",
              }}
            >
              Learn More About vNOTES
            </NavLink>

            <NavLink
              exact
              to="/eu/findasurgeon"
              className="navbar__nav-links"
              onClick={() => {
                setActiveLink("3");
                setClick(false);
              }}
              activeStyle={{
                color: "#365B7E",
                borderBottom: "2px solid #365B7E",
              }}
            >
              Find a vNOTES Surgeon
            </NavLink>
          </div>
        </li>
        <hr className="horizontal-line" />
        <li className="navbar__nav-item navbar__nav-item--4">
          <input
            type="checkbox"
            id="testimonials"
            name="testimonials"
            value="testimonials"
            className="navbar__checkbox"
          />
          <label
            className={
              activeLink === "4" ? "navbar__label active" : "navbar__label"
            }
            htmlFor="testimonials"
          >
            Testimonials
            <ChevronDown className="expansion-arrow" />
          </label>

          <div className="navbar__dropdown">
            <NavLink
              exact
              to="/eu/hcptestimonials"
              className="navbar__nav-links"
              onClick={() => {
                setActiveLink("4");
                setClick(false);
              }}
              activeStyle={{
                color: "#365B7E",
                borderBottom: "2px solid #365B7E",
              }}
            >
              Healthcare Professionals
            </NavLink>

            <NavLink
              exact
              to="/eu/patientstestimonials"
              className="navbar__nav-links"
              onClick={() => {
                setActiveLink("4");
                setClick(false);
              }}
              activeStyle={{
                color: "#365B7E",
                borderBottom: "2px solid #365B7E",
              }}
            >
              Patients
            </NavLink>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
