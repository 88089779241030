export const generateHreflangTags = (path, region) => {
    const baseUrl = window.location.origin || "https://corpwebappstaging-tamisrewrite.azurewebsites.net";

    // Define hreflang configurations for each region and language variant
    const hreflangConfigs = {
        EU: [
            { lang: "en-eu", url: `${baseUrl}/eu${path}` },
            // Add other languages as needed
        ],
        US: [
            { lang: "en-us", url: `${baseUrl}${path}` }
        ],
        // Add other regions here if needed
    };

    // Return the relevant hreflang configuration based on the region, or a default
    return hreflangConfigs[region] || hreflangConfigs['US']; // Default to 'US' if region not defined
};
