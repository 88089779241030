import React from "react";
import "../../../../App.css";
import "./Home.css";
import { EUBanner } from "../../../Banner/Banner";
import ReactTooltip from "react-tooltip";
import { cdn } from "../../../../consts/cdn";

//Images
const BannerDesktop = cdn.images + "Home/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "Home/Banner-Mobile.jpg";

function Home() {
  return (
    <div className="home">
      <EUBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="NO SCARS, LESS PAIN"
        text1="AND A QUICK RETURN "
        text2="TO YOUR BUSY LIFE"
        button1="Healthcare Professionals"
        route1="/eu/healthcareprofessionals"
        button2="Patients"
        route2="/eu/patients"
        color="#365B7E"
      />
      <ReactTooltip />
    </div>
  );
}

export default Home;
