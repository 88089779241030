import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./Redirect.css";
import { EUBanner } from "../../../Banner/Banner";
import { cdn } from "../../../../consts/cdn";

const BannerDesktop = cdn.images + "Redirect/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "Redirect/Banner-Mobile.jpg";

function Redirect() {
  return (
    <div className="redirect">
      <EUBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        openSans="true"
        text1="At this time, this website is not"
        text2="available in your region."
        color="#365B7E"
        htmlText="If you are a "
      />
    </div>
  );
}

export default Redirect;
