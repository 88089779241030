import React, { useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import "../../../App.css";
import "../../_Shared/utility.css";
import "./HCPModal.css";
import { Button } from "../../Button/Button";
import { ModalContext } from "../../../regionLayouts/EULayout";

function HCPModal() {
  const [, , isHCPModalVisible, setIsHCPModalVisible, forwardPath, ,] =
    useContext(ModalContext);
  const yesRadioButtonRef = useRef(null);
  const noRadioButtonRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (isHCPModalVisible) {
      yesRadioButtonRef.current.checked = false;
      noRadioButtonRef.current.checked = false;
    }
  }, [isHCPModalVisible]);

  function onConfirmSelection() {
    if (yesRadioButtonRef.current.checked || noRadioButtonRef.current.checked) {
      if (yesRadioButtonRef.current.checked) {
        sessionStorage.setItem("hcpConfirmed", "yes");
        history.push(forwardPath);
      } else if (noRadioButtonRef.current.checked) {
        sessionStorage.setItem("hcpConfirmed", "no");
        history.push("/eu/patients");
      }

      setIsHCPModalVisible(false);
    }
  }

  return (
    <div
      className={
        isHCPModalVisible
          ? "hcp-modal-background active"
          : "hcp-modal-background"
      }
    >
      <div className="hcp-modal-container">
        <p className="hcp-modal-header">Are you a healthcare professional?</p>

        <div className="hcp-disclaimer">
          <p>
            The information on the page you are about to enter is intended for
            healthcare professionals only.{" "}
          </p>
          <br />
          <p>
            By clicking on the button below, you confirm that you are a
            healthcare professional.
          </p>
        </div>

        <div className="hcp-modal-options-container">
          <div className="radio-button-container">
            <input
              type="radio"
              id="yes"
              name="hcp"
              value="yes"
              ref={yesRadioButtonRef}
            />
            <label className="selection-label" htmlFor="yes">
              Yes, I am a healthcare professional.
            </label>
          </div>
          <div className="radio-button-container">
            <input
              type="radio"
              id="no"
              name="hcp"
              value="no"
              ref={noRadioButtonRef}
            />
            <label className="selection-label" htmlFor="no">
              No, I am not a healthcare professional.
            </label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "30px",
          }}
        >
          <Button onClick={onConfirmSelection}>Confirm</Button>
        </div>
      </div>
    </div>
  );
}

export default HCPModal;
