export function checkHCPStatus(e, triggerModal, history, to, setForwardPath) {
  e.preventDefault();

  if (sessionStorage.getItem("hcpConfirmed") === "yes") {
    history.push(to);
  } else {
    setForwardPath(to);
    triggerModal(true);
  }
}
