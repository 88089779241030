import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./Patients.css";
import { EUBanner } from "../../../Banner/Banner";
import { Button } from "../../../Button/Button";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet-async";
import { cdn } from "../../../../consts/cdn";
import { slide1, slide2 } from "../../../Carousel/CarouselData.js";
import Carousel from "../../../Carousel/Carousel";
import { JwPlayer } from "../../../JwPlayer/JwPlayer.js";

//Images
const BannerDesktop = cdn.images + "Patients/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "Patients/Banner-Mobile.jpg";
const Image1a = cdn.images + "Patients/Image1a.jpg";
const Image1b = cdn.images + "Patients/Image1b.jpg";
const Image1c = cdn.images + "Patients/Image1c.jpg";
const Image1d = cdn.images + "Patients/Image1d.jpg";
const Image5d = cdn.images + "Patients/Image5d.svg";
const CircleBackgroundDesktop =
  cdn.images + "Patients/CircleBackground--Desktop.png";
const CircleBackgroundMobile =
  cdn.images + "Patients/CircleBackground--Mobile.png";

function Patients() {
  return (
    <div className="patients">
      <Helmet>
        <title>vNOTES Hysterectomy</title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) is another minimally invasive approach to consider for your hysterectomy and other gynecologic…"
        />
      </Helmet>
      <EUBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        boldTitle1="QUICKER RECOVERY."
        boldTitle2="LESS PAIN."
        boldTitle3="NO VISIBLE SCARS."
        subtitle="Find out if vNOTES is the right hysterectomy option for you."
        color="#EA6100"
      />
      <div className="content">
        <JwPlayer
          file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/fd7967b2-406a-41f8-ac9e-8e290ce80763/488310-EN-B vNOTES Patient-Facin.ism/manifest(format=m3u8-cmaf)"
          title="vNOTES Patient-Facing"
          autostart="true"
        ></JwPlayer>
        <div className="hcphome__extrapadding">
          <h1 className="header-primary header-primary--orange header-primary--comfortaa">
            What is{" "}
            <span
              style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}
            >
              v
            </span>
            NOTES?
          </h1>
          <p className="paragraph">
            About half a million hysterectomies are performed each year in the
            U.S. alone.
            <sup
              className="ref__style"
              data-background-color="#FAFAFA"
              data-text-color="#707070"
              data-tip="Cohen SL, Ajao MO, Clark NV, Vitonis AF, Einarsson JI. Outpatient hysterectomy volume in the United States. Obstet Gynecol. 2017;130:130-7
              <br />
              <br />
              Einarsson J. Are hysterectomy volumes in the US really falling? Contemporary OB/GYN website. Sept 1, 2017. Accessed July 9, 2020."
              data-class="sup__style"
              data-arrow-color="transparent"
              data-html="true"
            >
              REF
            </sup>
            If your doctor has recommended this procedure to treat a condition
            &ndash; such as pelvic pain, heavy periods or a prolapsed uterus
            &ndash;{" "}
            <span style={{ color: "#EA6100", fontWeight: "700" }}>vNOTES</span>{" "}
            (vaginal natural orifice transluminal endoscopic surgery) may be an
            option for you. In a{" "}
            <span style={{ color: "#EA6100", fontWeight: "700" }}>vNOTES</span>{" "}
            procedure, surgeons use specialized instruments inserted through the
            vagina instead of making incisions in the abdomen.
          </p>
        </div>
        <div className="patients__img-container patients__img-container--1">
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1a}
              alt="Open Surgery"
              loading="lazy"
            />
            <div className="patients__img-caption">Open Surgery</div>
          </div>
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1b}
              alt="Laparoscopic Surgery"
              loading="lazy"
            />
            <div className="patients__img-caption">Laparoscopic Surgery</div>
          </div>
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1d}
              alt="Laparoscopic Surgery"
              loading="lazy"
            />
            <div className="patients__img-caption">Robotic Surgery</div>
          </div>
          <div className="patients__img-flex-item">
            <img
              className="patients__img--1"
              src={Image1c}
              alt="vNOTES"
              loading="lazy"
            />
            <div className="patients__img-caption" style={{ color: "#4B759D" }}>
              vNOTES
            </div>
          </div>
        </div>

        <br className="br__desktop-only" />

        <div className="hcphome__extrapadding">
          <p className="paragraph">
            In addition to hysterectomies,{" "}
            <span style={{ color: "#EA6100", fontWeight: "700" }}>vNOTES</span>{" "}
            can be used for other gynecologic procedures:
          </p>

          <ul className="patients__list">
            <li>
              <p className="paragraph">Fallopian tube or ovarian procedure</p>
            </li>
            <li>
              <p className="paragraph">Permanent sterilization</p>
            </li>
            <li>
              <p className="paragraph">Pelvic organ prolapse repair</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="patients__section" style={{ background: "#fafafa" }}>
        <div className="patients__half-text hcphome__extrapadding">
          <h1
            className="header-primary--orange header-primary--comfortaa"
            style={{ margin: "0px" }}
          >
            vNOTES PROVIDES THE
            <br />
            FOLLOWING BENEFITS
            <br />
            COMPARED TO
            <br />
            LAPAROSCOPIC SURGERY
            <sup
              className="ref__style patients__header-ref"
              data-background-color="#FAFAFA"
              data-text-color="#707070"
              data-tip="Baekelandt JF, De Mulder PA, Le Roy I, et al. Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery versus laparoscopy as a day-care procedure: a randomised controlled trial. BJOG. 2019;126(1):105-113. doi:10.1111/1471-0528.15504"
              data-class="sup__style"
              data-arrow-color="transparent"
              data-html="true"
            >
              REF
            </sup>
            :
          </h1>
        </div>
        <div className="patients__half-img hcphome__extrapadding">
          <Carousel data={slide1} carousel_name="benefits-carousel" autoplay />
        </div>
      </div>

      <div className="content content--2 hcphome__extrapadding">
        <div className="patients__flex">
          <div className="patients__half-img patients__half-img--2">
            <img src={Image5d} alt="message icon" />
          </div>

          <div className="patients__half-text patients__text-right">
            <h1
              className="header-primary--orange header-primary--comfortaa"
              style={{ margin: "0px" }}
            >
              START THE <br className="br__mobile-only--patients" />
              CONVERSATION.
            </h1>
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button route="/findasurgeon" id="fas-patients-button">
                Find a vNOTES Surgeon
              </Button>
            </div>
            <br />
            <p className="paragraph paragraph--width">
              Like all surgeries, vNOTES has risks, including the possibility of
              conversion to traditional methods. Consult a vNOTES&#8209;trained
              doctor.
            </p>
          </div>
        </div>
      </div>

      <div className="hcphome__gray-container">
        <div
          className="content hcphome__right-content"
          style={{ position: "absolute", textAlign: "left" }}
        >
          <h1
            className="header-primary header-primary--orange header-primary--comfortaa"
            style={{ margin: "0px" }}
          >
            LEARN WHAT TO <br />
            EXPECT WITH <br className="br__mobile-only" />
            <span
              style={{ textTransform: "lowercase", fontFamily: "Comfortaa" }}
            >
              v
            </span>
            NOTES.{" "}
          </h1>
          <div>
            <Button
              route="/patientstestimonials"
              id="testimonials-patients-button"
            >
              View Patient Testimonials
            </Button>
          </div>
        </div>
        <img
          className="banner__img banner__img--desktop patients__banner--desktop"
          src={CircleBackgroundDesktop}
          alt=""
        />
        <img
          className="banner__img banner__img--mobile"
          src={CircleBackgroundMobile}
          alt=""
        />
      </div>

      <div className="content content--carousel">
        <Carousel
          data={slide2}
          carousel_name="procedure-carousel"
          singleImage
        />
      </div>

      <ReactTooltip />
    </div>
  );
}

export default Patients;
