import React, { useState } from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./ContactUs.css";
import { EUBanner } from "../../../Banner/Banner";
import { cdn } from "../../../../consts/cdn";
import { api } from "../../../../consts/api";

//Images
const BannerDesktop = cdn.images + "ContactUs/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "ContactUs/Banner-Mobile.jpg";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setNumber] = useState("");
  const [facility, setFacility] = useState("");
  const [facilityAddress, setFacilityAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [comments, setComment] = useState("");
  const [check, setCheck] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [validate, setValidate] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidate(true);
    setError(false);
    if (check === false) {
      const url = api + "/contactus";
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phoneNumber,
          facility,
          facilityAddress,
          city,
          state,
          comments,
        }),
      };
      fetch(url, requestOptions)
        .then((response) => {
          setValidate(false);
          setSubmit(true);
          setName("");
          setEmail("");
          setNumber("");
          setFacility("");
          setFacilityAddress("");
          setCity("");
          setState("");
          setComment("");
          console.log("Form submit success: ", response);
        })
        .catch((error) => {
          setValidate(false);
          setSubmit(false);
          setError(true);
          console.log("Form submit error: ", error);
        });
    } else {
      setValidate(false);
      setSubmit(true);
    }
  };

  return (
    <div className="contact-us">
      <EUBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="CONTACT US"
        color="#365B7E"
      />
      <div className="content">
        <div className="contact-us__form-container">
          {submit && !error ? (
            <div>
              <div className="success-animation">
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <p
                style={{
                  color: "#1b3b5a",
                  fontSize: "20px",
                  marginTop: "40px",
                  marginBottom: "100px",
                  textAlign: "center",
                }}
              >
                Thank you! Your submission has been received.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <p className="paragraph contact-us__paragraph">
                Contact us for more information about EU vNOTES and course
                availability in your region.
              </p>
              <div className="contact-us__input-container">
                <label className="contact-us__label">
                  Name{" "}
                  <span className="contact-us__span-required">(required)</span>
                </label>
                <input
                  className="contact-us__input"
                  type="text"
                  value={name}
                  name="name"
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </div>

              <div className="contact-us__input-container">
                <label className="contact-us__label">
                  Email Address{" "}
                  <span className="contact-us__span-required">(required)</span>
                </label>
                <input
                  className="contact-us__input"
                  type="email"
                  value={email}
                  name="email"
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
              </div>

              <div className="contact-us__input-container">
                <label className="contact-us__label">Phone Number </label>
                <input
                  className="contact-us__input"
                  type="number"
                  value={phoneNumber}
                  name="phoneNumber"
                  onChange={(event) => setNumber(event.target.value)}
                  required
                />
              </div>

              <div className="contact-us__input-container">
                <label className="contact-us__label">
                  Facility{" "}
                  <span className="contact-us__span-required">(required)</span>
                </label>
                <input
                  className="contact-us__input"
                  type="text"
                  value={facility}
                  name="facility"
                  onChange={(event) => setFacility(event.target.value)}
                  required
                />
              </div>

              <div className="contact-us__input-container">
                <label className="contact-us__label">
                  Facility Address{" "}
                  <span className="contact-us__span-required">(required)</span>
                </label>
                <input
                  className="contact-us__input"
                  type="text"
                  value={facilityAddress}
                  name="address"
                  onChange={(event) => setFacilityAddress(event.target.value)}
                  required
                />
              </div>

              <div className="contact-us__input-container">
                <label className="contact-us__label">
                  City{" "}
                  <span className="contact-us__span-required">(required)</span>
                </label>
                <input
                  className="contact-us__input"
                  type="text"
                  value={city}
                  name="city"
                  onChange={(event) => setCity(event.target.value)}
                  required
                />
              </div>

              {/* <div className="contact-us__location-container"> */}
              <div className="contact-us__input-container">
                <label className="contact-us__label">
                  State{" "}
                  <span className="contact-us__span-required">(required)</span>
                </label>
                <input
                  className="contact-us__input"
                  type="text"
                  value={state}
                  name="state"
                  onChange={(event) => setState(event.target.value)}
                  required
                />
              </div>
              {/* </div> */}

              <div className="contact-us__input-container">
                <label className="contact-us__label">Comments</label>
                <textarea
                  className="contact-us__textarea"
                  type="text"
                  value={comments}
                  name="comments"
                  onChange={(event) => setComment(event.target.value)}
                ></textarea>
              </div>

              <input
                type="checkbox"
                style={{ display: "none", visibility: "hidden" }}
                name="checkbox_add"
                id="checkbox_add"
                onClick={() => {
                  setCheck(!check);
                }}
              />

              {error ? (
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "40px",
                  }}
                >
                  Something went wrong. Please try again later.
                </p>
              ) : null}

              {validate ? (
                <div className="contact-us__button">
                  <div className="validate"></div>
                </div>
              ) : (
                <div className="contact-us__button">
                  <button className="btn" type="submit">
                    Submit
                  </button>
                </div>
              )}
            </form>
          )}

          <p className="paragraph contact-us__paragraph--disclaimer">
            The disclosure of this data is voluntary. By providing your personal
            data you give your consent to Applied Medical to process your
            personal data and follow up on your request for more information on
            the email address and/or phone number provided by you on this form.
            Applied Medical will not transfer your personal data to third
            parties unless required to do so by law and shall take all
            appropriate measures to protect your personal data from unauthorized
            access. We will retain your data in our CRM database. Your consent
            can be withdrawn at any time by contacting{" "}
            <a
              className="contact-us__anchor"
              href="mailto:vNOTES@appliedmedical.com"
            >
              vNOTES@appliedmedical.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
