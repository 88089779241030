import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

// Send event data to Google Analytics
export const sendEventGA = (eventName, eventParams) => {
  window.gtag("event", eventName, {
    ...eventParams,
  });
};

// Send event data to Google Tag Manager
export const sendEventGTM = (eventName, eventParams) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...eventParams,
  });
};

export const SetDefaultConsentMode = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());

  ReactGA.gtag("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
    functionality_storage: "denied",
    personalization_storage: "denied",
    security_storage: "denied",
  });
};

export const UpdateConsentMode = () => {
  ReactGA.gtag("consent", "update", {
    ad_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
    analytics_storage: "granted",
    functionality_storage: "granted",
    personalization_storage: "granted",
    security_storage: "granted",
  });
};

export const InitializeGA = (region, path) => {
  if (region === "EU" && path.startsWith("/eu")) {
    ReactGA.initialize("G-MKNCBGGVZ9");
  } else {
    ReactGA.initialize("G-VW1E9MCH3Z");
  }
};

export const InitializeGTM = (region, path) => {
  if (region === "EU" && path.startsWith("/eu")) {
    const tagManagerArgs = {
      gtmId: "GTM-5MNJ2XFJ",
    };
    TagManager.initialize(tagManagerArgs);
  } else {
    const tagManagerArgs = {
      gtmId: "GTM-MXWD38J8",
    };
    TagManager.initialize(tagManagerArgs);
  }
};
