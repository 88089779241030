import React from "react";
import "../../../../../App.css";
import "../../../../_Shared/utility.css";
import "./HCP-testimonials.css";
import "../Patients/Patient-testimonials.css";
import { TestimonalQuote } from "../../../../TestimonalQuote/TestimonalQuote";
import { USBanner } from "../../../../Banner/Banner";
import { Helmet } from "react-helmet-async";
import { cdn } from "../../../../../consts/cdn";
import { JwPlayer } from "../../../../JwPlayer/JwPlayer.js";

//images
const VideoThumbnail1 = cdn.images + "Testimonials/HCP/Thumbnail1.jpg";
const VideoThumbnail2 = cdn.images + "Testimonials/HCP/Thumbnail2.jpg";
const VideoThumbnail3 = cdn.images + "Testimonials/HCP/Thumbnail3.jpg";
const BannerDesktop = cdn.images + "Testimonials/HCP/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "Testimonials/HCP/Banner-Mobile.jpg";

function HCPTestimonial() {
  return (
    <div className="testimonial">
      <Helmet>
        <title>
          vNOTES – Advanced Minimally Invasive Gynecologic Procedures
        </title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) workshops provide a comprehensive understanding of procedural steps and instrumentation used…"
        />
      </Helmet>
      <USBanner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="TESTIMONIALS"
        text1="Healthcare Professionals"
        color="#365B7E"
        openSans="true"
      />
      <div className="content">
        <div className="hcp__video-container hcp__video-container--1">
          <div className="testimonial__content--1">
            <JwPlayer
              file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/3b0e820b-1207-4b12-aec9-6d9ac7159df6/vNOTES as Featured on ACOG TV.ism/manifest(format=m3u8-cmaf)"
              title="vNOTES as Featured on ACOG TV"
              image={VideoThumbnail1}
            ></JwPlayer>
            <div className="video-description">
              <p>vNOTES as Featured on ACOG TV</p>
            </div>
          </div>
        </div>

        <div className="hcp__video-container hcp__video-container--2">
          <div className="testimonial__content--2">
            <JwPlayer
              file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/866716b3-733f-48ea-85b3-2ba8f8205aef/382034-EN-USA-A_vNOTES_ A Surgeo.ism/manifest(format=m3u8-cmaf)"
              title="vNOTES: A Surgeon's Perspective on Procedural Benefits"
              image={VideoThumbnail2}
            ></JwPlayer>
            <div className="video-description">
              <p>vNOTES: A Surgeon's Perspective on Procedural Benefits</p>
            </div>
          </div>

          <div className="testimonial__content--2">
            <JwPlayer
              file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/3680c68c-4bb0-45b3-9a33-6ae70f85c949/389179-EN-USA-A_vNOTES-A PACU Nu.ism/manifest(format=m3u8-cmaf)"
              title="vNOTES: A PACU Nurse's Perspective on Patient Recovery"
              image={VideoThumbnail3}
            ></JwPlayer>
            <div className="video-description">
              <p>vNOTES: A PACU Nurse's Perspective on Patient Recovery</p>
            </div>
          </div>
        </div>

        <div className="hcp__div-review">
          <TestimonalQuote
            quote="We have found that with vNOTES patients recover more quickly and have less postoperative pain. Additionally, the OR time for the procedure is shorter and results in fewer postoperative complications."
            name="Jan Baekelandt,&nbsp;"
            credential="MD, PhD "
          />
        </div>

        <div className="hcp__div-review">
          <TestimonalQuote
            quote="Since starting vNOTES, the improvement in my patients’ recovery experience has been tremendous. They have almost no pain at all. Patients are requiring very few, if any, narcotics and many are doing well with just ibuprofen. All of my patients are able to leave the same day as the surgery."
            name="Erin Miller,&nbsp;"
            credential="DO "
          />
        </div>
      </div>
    </div>
  );
}

export default HCPTestimonial;
