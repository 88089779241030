import React, { useContext } from "react";
import "./Banner.css";
import { Button } from "../Button/Button";
import { ModalContext } from "../../regionLayouts/EULayout";
import { checkHCPStatus } from "../../utilities/CheckHCPStatus";
import { useHistory } from "react-router-dom";

export function USBanner(props) {
  const styles = {
    color: props.color,
  };

  return (
    <div className="banner">
      <img
        src={props.imgdesktop}
        alt="banner"
        className="banner__img banner__img--desktop"
      />
      <img
        src={props.imgmobile}
        alt="banner"
        className="banner__img banner__img--mobile"
      />
      <p className="banner__text" style={styles}>
        {props.bold1 ? (
          <span className="banner__text-bold">
            {props.bold1}
            <span className="home__header-span">
              <sup
                className="home__sup"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Baekelandt JF, De Mulder PA, Le Roy I, et al. Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery versus laparoscopy as a day-care procedure: a randomised controlled trial. <i>BJOG</i>. 2019;126(1):105-113. doi:10.1111/1471-0528.15504"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </span>
          </span>
        ) : (
          ""
        )}
        {props.text1 || props.text2 ? (
          <span
            className={
              props.openSans
                ? "banner__text-normal font__open-sans"
                : "banner__text-normal"
            }
          >
            {props.text1}
            <br className="br__mobile-only--text-1" />
            {props.text2}
          </span>
        ) : (
          ""
        )}
        {props.bold2 ? (
          <span className="banner__text-bold">{props.bold2}</span>
        ) : (
          ""
        )}
        {props.boldTitle1 && props.boldTitle2 && props.boldTitle3 ? (
          <>
            <span className="banner__text-bold">{props.boldTitle1}</span>
            <span className="banner__text-bold">{props.boldTitle2}</span>
            <span className="banner__text-bold">{props.boldTitle3}</span>
          </>
        ) : (
          ""
        )}
        {props.subtitle ? (
          <>
            <br />
            <span className="paragraph banner__subtitle">{props.subtitle}</span>
          </>
        ) : (
          ""
        )}
        {props.htmlText ? (
          <span className="banner__text-html">
            {props.htmlText}
            <span className="redirect__bold-text">healthcare professional</span>
            <span>, please email </span>
            <a
              href="mailto:vNOTES@appliedmedical.com"
              className="redirect__anchor"
            >
              vNOTES@appliedmedical.com
            </a>
            <span> to be contacted by a representative in your region.</span>
            <br />
            <br />
            <span>If you are a </span>
            <span className="redirect__bold-text">patient</span>
            <span>
              , please speak with your healthcare professional to learn more
              about&nbsp;vNOTES.
            </span>
          </span>
        ) : (
          ""
        )}
        {props.button1 || props.button2 ? (
          <span className="banner__btn-container">
            {props.button1 ? (
              <Button route={props.route1}>{props.button1}</Button>
            ) : (
              ""
            )}
            {props.button2 ? (
              <Button route={props.route2}>{props.button2}</Button>
            ) : (
              ""
            )}
          </span>
        ) : (
          ""
        )}
      </p>
    </div>
  );
}

export function EUBanner(props) {
  const styles = {
    color: props.color,
  };

  const [, , , setIsHCPModalVisible, , setForwardPath] =
    useContext(ModalContext);
  const history = useHistory();

  return (
    <div className="banner">
      <img
        src={props.imgdesktop}
        alt="banner"
        className="banner__img banner__img--desktop"
      />
      <img
        src={props.imgmobile}
        alt="banner"
        className="banner__img banner__img--mobile"
      />
      <p className="banner__text" style={styles}>
        {props.bold1 ? (
          <span className="banner__text-bold">
            {props.bold1}
            <span className="home__header-span">
              <sup
                className="home__sup"
                data-background-color="#FAFAFA"
                data-text-color="#707070"
                data-tip="Baekelandt JF, De Mulder PA, Le Roy I, et al. Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery versus laparoscopy as a day-care procedure: a randomised controlled trial. <i>BJOG</i>. 2019;126(1):105-113. doi:10.1111/1471-0528.15504"
                data-class="sup__style"
                data-arrow-color="transparent"
                data-html="true"
              >
                REF
              </sup>
            </span>
          </span>
        ) : (
          ""
        )}
        {props.text1 || props.text2 ? (
          <span
            className={
              props.openSans
                ? "banner__text-normal font__open-sans"
                : "banner__text-normal"
            }
          >
            {props.text1}
            <br className="br__mobile-only--text-1" />
            {props.text2}
          </span>
        ) : (
          ""
        )}
        {props.bold2 ? (
          <span className="banner__text-bold">{props.bold2}</span>
        ) : (
          ""
        )}
        {props.boldTitle1 && props.boldTitle2 && props.boldTitle3 ? (
          <>
            <span className="banner__text-bold">{props.boldTitle1}</span>
            <span className="banner__text-bold">{props.boldTitle2}</span>
            <span className="banner__text-bold">{props.boldTitle3}</span>
          </>
        ) : (
          ""
        )}
        {props.subtitle ? (
          <>
            <br />
            <span className="paragraph banner__subtitle">{props.subtitle}</span>
          </>
        ) : (
          ""
        )}
        {props.htmlText ? (
          <span className="banner__text-html">
            {props.htmlText}
            <span className="redirect__bold-text">healthcare professional</span>
            <span>, please email </span>
            <a
              href="mailto:vNOTES@appliedmedical.com"
              className="redirect__anchor"
            >
              vNOTES@appliedmedical.com
            </a>
            <span> to be contacted by a representative in your region.</span>
            <br />
            <br />
            <span>If you are a </span>
            <span className="redirect__bold-text">patient</span>
            <span>
              , please speak with your healthcare professional to learn more
              about&nbsp;vNOTES.
            </span>
          </span>
        ) : (
          ""
        )}
        {props.button1 || props.button2 ? (
          <span className="banner__btn-container">
            {props.button1 ? (
              <Button
                route={props.route1}
                onClick={(e) => {
                  checkHCPStatus(
                    e,
                    setIsHCPModalVisible,
                    history,
                    "/eu/healthcareprofessionals",
                    setForwardPath
                  );
                }}
              >
                {props.button1}
              </Button>
            ) : (
              ""
            )}
            {props.button2 ? (
              <Button route={props.route2}>{props.button2}</Button>
            ) : (
              ""
            )}
          </span>
        ) : (
          ""
        )}
      </p>
    </div>
  );
}
