import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import "../../../App.css";
import "../../_Shared/utility.css";
import "./DataConsentModal.css";
import { Button } from "../../Button/Button";
import { ModalContext } from "../../../regionLayouts/EULayout";
import {
  InitializeGA,
  InitializeGTM,
  UpdateConsentMode,
} from "../../../utilities/GoogleAnalyticsEvents";

function DataConsentModal({ region }) {
  const location = useLocation();
  const [isDataConsentModalVisible, setIsDataConsentModalVisible] =
    useContext(ModalContext);

  useEffect(() => {
    // Delay the modal appearance by 1 second
    const timer = setTimeout(() => {
      if (!sessionStorage.getItem("dataConsentDecisionConfirmed")) {
        setIsDataConsentModalVisible(true);
      }
    }, 1500); // 1000 ms = 1 second

    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, [setIsDataConsentModalVisible]);

  function handleConsentSelection(e, consent) {
    e.preventDefault();

    if (consent) {
      UpdateConsentMode();
      InitializeGA(region, location.pathname);
      InitializeGTM(region, location.pathname);
    }

    sessionStorage.setItem("dataConsentDecisionConfirmed", "true");
    setIsDataConsentModalVisible(false);
  }

  return (
    <div
      className={`${
        isDataConsentModalVisible
          ? "data-consent-modal-background active"
          : "data-consent-modal-background"
      }`}
    >
      <div className="data-consent-modal-container">
        <div className="data-consent-modal-header">
          <h3 className="header--primary--blue modal__header--primary">
            This website uses cookies.
          </h3>
          <br />
          <p>
            We use cookies and similar technologies in order for the website to
            operate correctly, to understand how visitors engage with us and to
            improve our product and marketing efforts and provide a better
            experience. By clicking on "Accept" you agree to this, as outlined
            in our{" "}
            <a href={"https://appliedmedical.eu/Legal/PrivacyPolicy"}>
              Privacy Policy
            </a>
          </p>
        </div>
        <div className="modal__button-container">
          <div className="modal__btn-left-container">
            <Button
              className="modal__btn--left"
              route="/eu/"
              aria-label="Accept All Cookies"
              onClick={(e) => {
                handleConsentSelection(e, true);
              }}
            >
              Accept All Cookies
            </Button>
          </div>
          <div className="modal__btn-right-container">
            <Button
              className="modal__btn--right"
              route="/eu/"
              aria-label="Continue Without Accepting"
              onClick={(e) => {
                handleConsentSelection(e, false);
              }}
            >
              Continue Without Accepting
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataConsentModal;
